import { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import LoginBlock from "./components/LoginBlock";
import UserBlock from "./components/UserBlock";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// eslint-disable-next-line no-unused-vars
import app from "./components/firebase-config";
import { ToastContainer } from "react-toastify";

const auth = getAuth();

function App() {
  const [user, setUser] = useState(null);
  // const [userJWT, setUserJWT] = useState(null);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (authuser) => {
      if (authuser) {
        setUser(authuser);
        // authuser
        //   .getIdToken(true)
        //   .then((idToken) => {
        //     setUserJWT(idToken);
        //   })
        //   .catch(function (error) {
        //     console.error("Missing JWT");
        //   });
      } else {
        setUser(null);
      }
    });
  }, []);
  return (
    <>
      <Router>{user ? <UserBlock user={user} /> : <LoginBlock />}</Router>
      <ToastContainer />
    </>
  );
}

export default App;
