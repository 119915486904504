const Homepage = ({ user }) => {
  return (
    <div className="grow w-full flex flex-col items-center">
      {/*
     <div className="w-11/12 bg-raccoon-500/90 p-4 my-5 rounded-lg text-gray-200 shadow-indigo-500 ">
        <p className="my-2">Hejj, {user.displayName}!</p>
        <p className="my-2">Sveikiņi jaunajā jenotu lapas migā (serverī)!</p>
        <p className="my-2">Ja Tu šo redzi, tas nozīmē, ka pārcelšanās uz jauno serveri ir bijusi veiksmīga, bet jābrīdina, ka dažas atsevišķās lapas sadaļas (piemēram, jenotu spēle, atļaujas ģenerators, e-pasta sistēma) vēl nedarbojas, un par to atjaunošanu informēsim atsevišķi.</p>
        <p className="my-2">Crazy Coding Raccoons 🦝</p>
      </div>
     */}
      <div className="w-11/12 bg-raccoon-500/70 p-4 my-5 rounded-lg text-gray-200 shadow-indigo-500 ">
        {/* Hei, jenotu draugs! 🦝
      <br />
      <br />
      Redzam, ka Tu itin bieži ciemojies pie mums. Kopīgi esam šeit dalījušies ar viltīgumu un mīlīgumu, bonusa un soda punktiem, svētku gaidīšanas prieku, apēstajām kūkām, (ne)apciemotajām vietām un (ne)izdarītajām lietām.
      <br />
      <br />
      Tomēr pēdējā laikā jenotu migā rosība apsīkusi, mazāk mīlīguma un viltīguma, bet vairāk pūcīguma. Tāpēc jenotu komanda dodas prom no migas - padomāt un pārdomāt, kas noticis un kā rosīties tālāk. Bet daudz nebēdā, jo jenoti taču ir viltīgi - tie var parādīties atkal, arī brīžos, kad vismazāk
      to gaidi.
      <br />
      <br />
      Uz tikšanos labākos laikos!
      <br />
      JENOTI */}
        Hei, kapibaru un jenotu draugs!
        <br />
        <br />
        Ja Tev kādreiz pietrūkst ideju, ko darīt, kurp doties gardu kūku un citu gardumu meklējumos, <a href="https://viltigaisjenots.lv/v3/map">ieskaties mūsu lielajā ideju krātuvē</a>!
        <br />
        <br />
        Pievieno arī savas idejas, ja vēlies!
        <br />
        <br />
        JENOTI 🦝
      </div>
    </div>
  );
};

export default Homepage;
