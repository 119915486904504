import { useEffect } from "react";
import { ReactP5Wrapper } from "@p5-wrapper/react";
import { getIdToken } from "firebase/auth";
import emojiImage from "./miligais_jenots_emoji2.png";
import bgImage from "./bg.png";
import foxImage from "./foxface.png";
import cakeImage from "./cake.png";
import strawberryImage from "./strawberry.png";

const Jenotuspele = ({ user }) => {
  useEffect(() => {
    window.alert("Laipni lūgti jenotu spēlē! \n \n Jenotiem garšo kūkas, bet vēl vairāk tiem patīk ZEMENES! \n Uzmanies no viltīgajām lapsām! \n\n Lai pārvietotu jenotu, izmanto bultiņas uz klaviatūras!");
  }, []);

  const sketch = (p5) => {
    let hkey;
    let boundariesx = [50, 550];
    let redbg = 0;
    let kukas = [];
    let lapsas = [];
    let paused = false;
    let count = [0, 0, 0, 0, 0];
    let pausetimes = [];
    let gt = 0;
    let img, img2, img3, img4, bg, bumba, gravity, button;

    class Aplis {
      constructor() {
        this.x = 50;
        this.vspeed = 0;
        this.hspeed = 0;
        this.r = 15;
        this.y = -this.r;
        this.jump = 0;
        this.immune = 0;
        this.lives = 5;
        this.points = 0;
        this.level = 0;
        gt = Math.floor(Date.now() / 1000);
        this.sentresults = false;
      }

      update = () => {
        this.level = p5.floor(this.points / 20);
        if (this.immune !== 0) {
          this.immune--;
        }
        if (redbg !== 0) {
          redbg--;
        }

        this.x = this.x + this.hspeed;

        if (this.x < boundariesx[0]) {
          this.x = boundariesx[0];
        }
        if (this.x > boundariesx[1]) {
          this.x = boundariesx[1];
        }

        this.y = this.y + this.vspeed;
        if (this.y + this.r >= 0) {
          this.jump = 0;
          this.y = -this.r;
          this.vspeed = -this.vspeed * 0.3;
          if (Math.abs(this.vspeed) < 0.8) {
            this.vspeed = 0;
          }
        } else {
          this.vspeed = this.vspeed + gravity;
        }
      };

      show = () => {
        p5.fill(redbg, 0, 0);
        p5.textSize(16);
        p5.text("Dzīvības: " + this.lives, 5, 20);
        p5.text("Punkti: " + this.points, 155, 20);
        if (this.lives === 0) {
          p5.text("Paldies, ka uzspēlēji!", 300, 20);
          button.show();
          if (!this.sentresults) {
            this.sentresults = true;
            sendResults(this.points);
          }
        }
        p5.noFill();
        p5.imageMode(p5.CENTER);
        p5.image(img, this.x, this.y);
      };
    }

    const sendResults = async (gamePoints) => {
      let gameTime = Math.floor(Date.now() / 1000) - gt;
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: user.displayName,
          points: gamePoints,
          gametime: gameTime,
          stats: `${count.concat(pausetimes).join(",")}`,
        }),
      };
      const accessToken = await getIdToken(user);
      fetch(`https://${process.env.REACT_APP_BACKEND_URL}/postgameresults?token=${accessToken}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          //console.log(data);
        });
    };

    class Enemy {
      constructor() {
        this.x = boundariesx[1];
        this.hspeed = 1.5;
        this.r = 15;
        this.y = -this.r;
      }

      update = () => {
        if (p5.random(1) < 0.005 || this.x < boundariesx[0] || this.x > boundariesx[1]) {
          this.hspeed = this.hspeed * -1;
        }
        this.x = this.x + this.hspeed * (1 + (bumba.level % 5) / 5);
        if (checkcol(this, bumba)) {
          if (bumba.immune === 0) {
            bumba.immune = 120;
            bumba.lives--;
            redbg = 255;
          }
        }
      };

      show = () => {
        p5.imageMode(p5.CENTER);
        p5.image(img2, this.x, this.y);
      };
    }

    class Treat {
      constructor() {
        this.x = boundariesx[0];
        this.hspeed = p5.random(1, 2);
        this.vspeed = p5.random(-0.5, 0.5);
        this.r = 15;
        this.y = p5.random(-100, -400);
        this.pointvalue = 1;
        this.catch = false;

        if (p5.random(1) < 0.5) {
          this.x = boundariesx[1];
          this.hspeed = -p5.random(1, 2);
        } else {
          this.x = boundariesx[0];
          this.hspeed = p5.random(1, 2);
        }

        if (p5.random(1) < 0.8) {
          this.pointvalue = 1;
          this.showimg = img4;
          count[0]++;
        } else {
          this.pointvalue = 5;
          this.showimg = img3;
          count[2]++;
        }
      }

      update = () => {
        this.x = this.x + this.hspeed;
        this.y = this.y + this.vspeed;
        if (checkcol(this, bumba)) {
          if (!this.catch) {
            bumba.points += this.pointvalue;
            this.catch = true;
            if (this.pointvalue === 1) {
              count[1]++;
            } else {
              count[3]++;
            }
          }
        }
      };

      show = () => {
        if (!this.catch) {
          //ellipse(this.x,this.y,this.r*2,this.r*2);
          p5.imageMode(p5.CENTER);
          p5.image(this.showimg, this.x, this.y);
        }
      };
    }

    const checkcol = (obj1, obj2) => {
      let xdif = p5.abs(obj1.x - obj2.x);
      let ydif = p5.abs(obj1.y - obj2.y);
      let diff = p5.sqrt(xdif * xdif + ydif * ydif);
      if (diff < obj1.r + obj2.r) {
        return true;
      } else {
        return false;
      }
    };

    const restartGame = () => {
      //getresults();
      button.hide();
      bumba = new Aplis();
      lapsas = [];
      count = [0, 0, 0, 0, 0];
      pausetimes = [];
      lapsas.push(new Enemy());
    };

    const pauseGame = () => {
      if (paused === false) {
        paused = true;
        count[4]++;
        pausetimes.push(Math.floor(Date.now() / 1000) - gt);
      } else {
        paused = false;
        pausetimes.push(Math.floor(Date.now() / 1000) - gt);
      }
    };

    p5.keyPressed = () => {
      if (p5.keyCode === p5.UP_ARROW && bumba.jump < 2) {
        bumba.vspeed = -9;
        bumba.jump++;
      }

      if (p5.keyCode === p5.RIGHT_ARROW) {
        bumba.hspeed = 2;
        hkey = p5.RIGHT_ARROW;
      }

      if (p5.keyCode === p5.LEFT_ARROW) {
        bumba.hspeed = -2;
        hkey = p5.LEFT_ARROW;
      }
      if (p5.keyCode === 32) {
        pauseGame();
      }
    };

    p5.keyReleased = () => {
      if (p5.keyCode === hkey) {
        bumba.hspeed = 0;
        hkey = "";
      }
    };

    p5.setup = () => {
      p5.createCanvas(600, 550);
      img = p5.loadImage(emojiImage);
      img2 = p5.loadImage(foxImage);
      img3 = p5.loadImage(strawberryImage);
      img4 = p5.loadImage(cakeImage);
      bg = p5.loadImage(bgImage);

      bumba = new Aplis();
      lapsas.push(new Enemy());
      gravity = 0.2;
      //getresults();

      button = p5.createButton("Spēlēt vēlreiz");
      button.position(0, 10, "relative");
      button.addClass("restartButton");
      button.mousePressed(restartGame);
      button.hide();
    };

    p5.draw = () => {
      p5.background(255, 255, 255);
      p5.imageMode(p5.CENTER);
      p5.image(bg, 300, 225, 600, 550);
      p5.translate(0, 500);
      p5.fill(0, 0, 0);
      p5.textSize(12);
      p5.text(p5.round(p5.frameRate()), 580, 20);

      if (p5.frameCount % 100 === 0 && bumba.lives > 0 && !paused) {
        kukas.push(new Treat());
      }

      if (lapsas.length !== p5.floor(bumba.level / 5) + 1) {
        lapsas.push(new Enemy());
      }

      for (let i = lapsas.length - 1; i >= 0; i--) {
        if (bumba.lives > 0 && !paused) {
          lapsas[i].update();
        }
        lapsas[i].show();
      }

      for (let i = kukas.length - 1; i >= 0; i--) {
        if (bumba.lives > 0 && !paused) {
          kukas[i].update();
        }
        if (kukas[i].x > boundariesx[1] || kukas[i].x < boundariesx[0] || kukas[i].catch) {
          kukas.splice(i, 1);
        } else {
          kukas[i].show();
        }
      }

      if (bumba.lives > 0 && !paused) {
        bumba.update();
      }
      bumba.show();
    };
  };

  return (
    <div className="grow w-full flex flex-col items-center p-10">
      <ReactP5Wrapper sketch={sketch} />
    </div>
  );
};

export default Jenotuspele;
