import { getAuth, signOut } from "firebase/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { Routes, Route, Link } from "react-router-dom";
import B40 from "./B40";
import Map from "./Map";
import Homepage from "./Homepage";
import InputFormMap from "./InputFormMap";
import Saraksts from "./Saraksts";
import Checkin from "./Checkin";
import Jenotuspele from "./jenotuspele/Jenotuspele";
import logo from "../cdraccoon_sm.png";

const UserBlock = ({ user }) => {
  const toggleMenu = () => {
    document.querySelector("#mainMenu").classList.toggle("hidden");
  };

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <div className="fullcont">
      <div className="w-full bg-raccoon-700 block xl:flex">
        <div className="flex p-4">
          <div className="header-logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="flex xl:hidden grow items-center justify-end">
            <button className="flex items-center px-3 py-2 border rounded text-raccoon-200 border-raccoon-300 hover:text-white hover:border-white" onClick={toggleMenu}>
              <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
        </div>

        <div id="mainMenu" className="hidden xl:flex flex-col xl:flex-row w-full justify-center xl:justify-start items-end font-radiocanada">
          <ul className="px-4 py-2 text-base text-gray-400 xl:flex gap-4 h-full justify-center items-center text-end xl:text-center">
            <li>
              <Link to="/v3">Sākumlapa</Link>
            </li>
            <li>
              <Link to="/v3/saraksts">Saraksts</Link>
            </li>
            <li>
              <Link to="/v3/map">Karte</Link>
            </li>
            <li>
              <Link to="/v3/addItem">Pievieno!</Link>
            </li>
            <li>
              <Link to="/v3/jenotuspele">Jenotu spēle</Link>
            </li>
            <li>
              <Link to="/v3/b40">B40 tāme</Link>
            </li>
          </ul>
          <div className="h-full text-gray-400 flex justify-end items-center grow">
            {user.displayName} ({user.email}) <FontAwesomeIcon className="logout" onClick={handleLogout} icon={faRightFromBracket} />
          </div>
        </div>

        {/* <div className="user-info">
          {user.displayName}
          <br />({user.email})
          <FontAwesomeIcon className="logout" onClick={handleLogout} icon={faRightFromBracket} />
        </div> */}
      </div>

      <Routes>
        <Route path="/v3" element={<Homepage user={user} />} />
        <Route path="/v3/b40" element={<B40 user={user} />} />
        <Route path="/v3/map" element={<Map user={user} />} />
        <Route path="/v3/addItem" element={<InputFormMap user={user} />} />
        <Route path="/v3/saraksts" element={<Saraksts user={user} />} />
        <Route path="/v3/checkin" element={<Checkin user={user} />} />
        <Route path="/v3/jenotuspele" element={<Jenotuspele user={user} />} />
      </Routes>

      <div className="footer">© 2023 - 2024 Viltīgo jenotu komanda</div>
    </div>
  );
};

export default UserBlock;
