// Import the functions you need from the SDKs you

import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyAW-5nG_Rqkjeu_-Q2CVAq8G-a9Gwp70es",

  authDomain: "chatraccoon-ca398.firebaseapp.com",

  projectId: "chatraccoon-ca398",

  storageBucket: "chatraccoon-ca398.appspot.com",

  messagingSenderId: "994938097994",

  appId: "1:994938097994:web:f27a1621de1af442abf1b3",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
export default app;
