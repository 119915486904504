const B40item = ({ data }) => {
  const addZero = (number) => {
    if (!number.toString().split(".")[1]) {
      return `${number}.00`;
    } else if (+number.toString().split(".")[1].length < 2) {
      return `${number}0`;
    } else {
      return number.toString();
    }
  };

  return (
    <div className="tableRow">
      <div className="tableCell">{new Date(data.datums).toLocaleDateString("lv-LV")}</div>
      <div className="tableCell">{data.nosaukums}</div>
      <div className="tableCell">{addZero(data.summa)}</div>
      <div className="tableCell">{data.sanemejs}</div>
      <div className="tableCell">{data.samaksaja}</div>
      <div className="tableCell">{data.piezimes}</div>
    </div>
  );
};

export default B40item;
