import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
// eslint-disable-next-line no-unused-vars
import app from "./firebase-config";
import logo from "../cdraccoon_sm.png";

const auth = getAuth();

const handleSubmit = (e) => {
  const email = e.target.elements.email.value;
  const passw = e.target.elements.password.value;

  signInWithEmailAndPassword(auth, email, passw)
    .then((userCredential) => {
      //console.log("ielogojies");
    })
    .catch((error) => {
      alert("Nepareizi pieejas dati.");
    });

  e.preventDefault();
};

const LoginBlock = () => {
  const show = () => {
    document.querySelector("img").classList.add("top");
    document.querySelector("#loginForm").classList.add("visible");
  };

  return (
    <div className="cont">
      <img className="logo" alt="logo" src={logo} onClick={show} />
      <form id="loginForm" className="hidden" onSubmit={handleSubmit}>
        <span className="hint">e-pasts (tavsvards@viltigaisjenots.lv)</span>
        <input name="email" autoComplete="off" />
        <span className="hint">parole</span>
        <input type="password" name="password" />
        <button>Login</button>
      </form>
    </div>
  );
};

export default LoginBlock;
