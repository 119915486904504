import { getIdToken } from "firebase/auth";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import parse from "html-react-parser";
import InlineComments from "./InlineComments";

const Saraksts = ({ user }) => {
  const fetchData = async () => {
    const accessToken = await getIdToken(user);
    fetch(`https://${process.env.REACT_APP_BACKEND_URL}/mapdata?token=${accessToken}`)
      .then((response) => response.json())
      .then((d) => setMapData(d));
  };

  const [mapData, setMapData] = useState([]);
  const [searchPhrase, setSearchPhrase] = useState("");
  const [filterValues, setFilterValues] = useState({ vk: true, kk: true, idea: true });
  const [sortParams, setSortParams] = useState({ param: "laiks", direction: -1 });
  const [showCommentBlock, setShowCommentBlock] = useState(0);

  const makeSortFunction = (param, direction) => {
    if (param === "nosaukums") {
      return function (a, b) {
        return new Intl.Collator("lv").compare(a.nosaukums, b.nosaukums) * direction;
      };
    } else if (param === "laiks") {
      return function (a, b) {
        return (a.laiks - b.laiks) * direction;
      };
    } else if (param === "lastcomment") {
      return function (a, b) {
        return (a.lastComment - b.lastComment) * direction;
      };
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="w-full h-full flex flex-col items-center grow">
      <div className="w-11/12 bg-raccoon-700/70 p-4 my-2 rounded-lg text-gray-200 shadow-indigo-500 flex flex-col lg:flex-row justify-start items-start lg:items-center gap-3 lg:gap-8">
        <div className="w-full lg:w-auto flex items-center">
          <b>Meklēt</b> <input className="m-2 p-1 w-1/2 lg:w-auto text-xs bg-gray-200 text-gray-700 rounded-md border-raccoon-600 focus:outline-none" onChange={(e) => setSearchPhrase(e.target.value)} />
        </div>
        <div className="flex gap-3">
          <label>
            <input className="mx-1" type="checkbox" checked={filterValues.kk} onChange={(e) => setFilterValues({ ...filterValues, kk: !filterValues.kk })} /> Kūkas
          </label>
          <label>
            <input className="mx-1" type="checkbox" checked={filterValues.vk} onChange={(e) => setFilterValues({ ...filterValues, vk: !filterValues.vk })} /> Ņam-ņam
          </label>
          <label>
            <input className="mx-1" type="checkbox" checked={filterValues.idea} onChange={(e) => setFilterValues({ ...filterValues, idea: !filterValues.idea })} /> Idejas
          </label>
        </div>
        <div className="flex gap-3">
          <button onClick={(e) => setSortParams({ param: "nosaukums", direction: -sortParams.direction })}>
            {sortParams.param === "nosaukums" && sortParams.direction === 1 ? <FontAwesomeIcon icon={faSortUp} /> : ""}
            {sortParams.param === "nosaukums" && sortParams.direction === -1 ? <FontAwesomeIcon icon={faSortDown} /> : ""} Nosaukums
          </button>
          <button onClick={(e) => setSortParams({ param: "laiks", direction: -sortParams.direction })}>
            {sortParams.param === "laiks" && sortParams.direction === 1 ? <FontAwesomeIcon icon={faSortUp} /> : ""}
            {sortParams.param === "laiks" && sortParams.direction === -1 ? <FontAwesomeIcon icon={faSortDown} /> : ""} Datums
          </button>
          <button onClick={(e) => setSortParams({ param: "lastcomment", direction: -sortParams.direction })}>
            {sortParams.param === "lastcomment" && sortParams.direction === 1 ? <FontAwesomeIcon icon={faSortUp} /> : ""}
            {sortParams.param === "lastcomment" && sortParams.direction === -1 ? <FontAwesomeIcon icon={faSortDown} /> : ""} Pēd. komentārs
          </button>
        </div>
      </div>
      {Array.isArray(mapData) &&
        mapData
          .filter((e) => e.nosaukums.match(new RegExp(searchPhrase, "gi")) || e.adrese.match(new RegExp(searchPhrase, "gi")) || e.komentars.match(new RegExp(searchPhrase, "gi")))
          .filter((e) => filterValues[e.tabula])
          .sort(makeSortFunction(sortParams.param, sortParams.direction))
          .map((e) => {
            let sourceText = "";
            if (e.tabula === "vk") {
              sourceText = "vietu kartē";
            } else if (e.tabula === "kk") {
              sourceText = "kūku kartē";
            } else if (e.tabula === "idea") {
              sourceText = "ideju kartē";
            }
            return (
              <div className="w-11/12 bg-raccoon-500/70 p-4 my-2 rounded-lg text-gray-200 shadow-indigo-500" key={e.id}>
                <b>{e.nosaukums}</b> {e.adrese && <span className="text-xs">({e.adrese})</span>}
                <br />
                {parse(e.komentars)}
                <br />
                <span className="text-xs">
                  {e.uname} pievienoja {sourceText} {e.laiks > 0 ? new Date(parseInt(e.laiks) * 1000).toLocaleDateString("lv-LV") : ""} <button onClick={() => (showCommentBlock === e.id ? setShowCommentBlock(0) : setShowCommentBlock(e.id))}>Komentāri ({e.commentCount})</button>
                </span>
                {showCommentBlock === e.id ? <InlineComments user={user} ideaID={e.id} /> : ""}
              </div>
            );
          })}
    </div>
  );
};

export default Saraksts;
