import { useEffect, useState } from "react";
import { feature } from "@rapideditor/country-coder";

const Checkin = ({ user }) => {
  const [coords, setCoords] = useState({});

  const getCoords = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((p) => {
        setCoords({ lat: p.coords.latitude, lon: p.coords.longitude, time: p.timestamp, feature: feature([p.coords.longitude, p.coords.latitude]) });
      });
    }
  };

  const saveCheckin = () => {};

  return (
    <div className="grow flex items-center justify-center w-full">
      <div className="p-2 my-2 mx-5 bg-raccoon-700/80 rounded-md w-full lg:w-3/5 xl:w-1/2 2xl:w-2/5 flex flex-col items-center justify-center ">
        <div className="text-gray-100 m-2">
          {coords.lat && coords.lon && (
            <>
              {coords.lat}, {coords.lon}
            </>
          )}
        </div>
        <div className="text-gray-100 m-2">{coords.time && new Date(coords.time).toUTCString()}</div>
        <div className="m-3">
          {coords.feature && (
            <>
              <div className="text-center text-gray-100 text-xl">
                {coords.feature.properties.nameEn} {coords.feature.properties.emojiFlag}
              </div>
              <button className="py-1 px-8 bg-indigo-200 hover:bg-indigo-300 shadow-sm shadow-indigo-500 rounded-xl m-3 text-sm border-gray-300" onClick={saveCheckin}>
                Iečekoties
              </button>
            </>
          )}
        </div>
        <div>
          <button className="py-1 px-8 bg-indigo-200 hover:bg-indigo-300 shadow-sm shadow-indigo-500 rounded-xl m-3 text-sm border-gray-300" onClick={getCoords}>
            Iegūt atrašanās vietas koordinātas
          </button>
        </div>
      </div>
    </div>
  );
};

export default Checkin;
