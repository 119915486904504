import { getIdToken } from "firebase/auth";
import { useEffect, useState } from "react";
import ModalImage from "react-modal-image";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import "react-toastify/dist/ReactToastify.css";

const InlineComments = ({ user, ideaID }) => {
  const [commentData, setCommentData] = useState({});
  const [formData, setFormData] = useState({ idea_id: ideaID });

  const fetchData = async () => {
    const accessToken = await getIdToken(user);
    fetch(`https://${process.env.REACT_APP_BACKEND_URL}/commentdata?id=${ideaID}&token=${accessToken}`)
      .then((response) => response.json())
      .then((d) => setCommentData(d));
  };

  const handleChange = (event) => {
    if (event.target.name === "flags" && event.target.checked === false) {
      event.target.value = 0;
    }
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const accessToken = await getIdToken(user);
    if (formData.komentars) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      };

      fetch(`https://${process.env.REACT_APP_BACKEND_URL}/postcomment?token=${accessToken}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            toast.error("Bugzzz.", {
              position: "bottom-center",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "dark",
            });
          } else {
            setFormData({ idea_id: ideaID });
            fetchData();
            toast.success("Happibara!!", {
              position: "bottom-center",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
          }
        });
    } else {
      toast.error("Nepuce jenotus! 🦉", {
        position: "bottom-center",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="w-full h-full flex flex-col items-center grow">
      {commentData.comments && (
        <>
          {commentData.comments
            .sort((a, b) => b.laiks - a.laiks)
            .map((e) => {
              let addText = "";
              if (e.flags === -1) {
                addText = "atzīmēja VIETA NEEKSISTĒ 😔";
              } else if (e.flags === 1) {
                addText = "atzīmēja IZDARĪTS ✔️";
              } else if (e.flags === 2) {
                addText = "atzīmēja NEIZDARĪTS ❌";
              } else {
                addText = "pievienoja komentāru";
              }
              return (
                <div className="w-full bg-raccoon-600/80 p-3 my-1 rounded-lg text-gray-200 shadow-indigo-500 flex" key={e.id}>
                  <div className="grow">
                    {parse(e.text)}
                    <br />
                    <span className="text-xs py-2">
                      {e.uname} {addText} {e.laiks > 0 ? new Date(parseInt(e.laiks) * 1000).toLocaleDateString("lv-LV") : ""}
                    </span>
                  </div>
                  <div className="shrink-0">
                    {e.img && (
                      <>
                        <ModalImage className="w-16 h-16 object-cover border-white border-2 drop-shadow-lg rounded-full" small={`https://viltigaisjenots.lv/${e.img}`} large={`https://viltigaisjenots.lv/${e.img}`} alt={e.text} hideZoom={true} hideDownload={true} />
                      </>
                    )}
                  </div>
                </div>
              );
            })}
        </>
      )}
      <div className="p-2 my-2 bg-raccoon-700/80 rounded-lg w-full flex flex-col items-center">
        <textarea className="w-11/12 lg:w-3/5 xl:w-1/2 2xl:w-2/5 m-2 p-2 text-gray-700 bg-blue-50 border-raccoon-200 border-2 text-sm resize-none rounded-md focus:outline-none" name="komentars" onChange={handleChange} value={formData.komentars || ""}></textarea>
        <div className="text-gray-100 flex flex-col md:flex-row items-center m-1 text-xs">
          <div className="flex gap-5 m-1">
            <label>
              <input type="checkbox" name="flags" value="-1" onChange={handleChange} checked={formData.flags === "-1"} /> Neeksistē
            </label>
            <label>
              <input type="checkbox" name="flags" value="1" onChange={handleChange} checked={formData.flags === "1"} /> Izdarīts
            </label>
            <label>
              <input type="checkbox" name="flags" value="2" onChange={handleChange} checked={formData.flags === "2"} /> Neizdarīts
            </label>
          </div>
          <div className="m-1">
            <button className="py-1 px-8 bg-indigo-500 hover:bg-indigo-400 shadow-sm shadow-indigo-500 rounded-xl m-1 text-sm border-gray-300" onClick={handleSubmit}>
              Pievienot
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InlineComments;
