import { getIdToken } from "firebase/auth";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import { Link } from "react-router-dom";

const InputFormMap = ({ user }) => {
  const [formData, setFormData] = useState({});

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const accessToken = await getIdToken(user);
    if (formData.nosaukums && formData.komentars && formData.cat) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      };

      fetch(`https://${process.env.REACT_APP_BACKEND_URL}/postidea?token=${accessToken}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            toast.error("Kļūda pievienojot ierakstu.", {
              position: "bottom-center",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "dark",
            });
          } else {
            setFormData({ cat: "idea" });
            toast.success("Ieraksts pievienots!", {
              position: "bottom-center",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
          }
        });
    } else {
      toast.error("Aizpildi nepieciešamos lauciņus un netroļļo!", {
        position: "bottom-center",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const geoCode = (e) => {
    e.preventDefault();
    if (formData.adrese) {
      fetch(`https://api.geoapify.com/v1/geocode/search?text=${formData.adrese}&apiKey=ed5f30572a8d4ccb8cd36208c8e7c34e`)
        .then((response) => response.json())
        .then((data) => {
          let result = data.features[0].properties;
          setFormData({ ...formData, lat: result.lat, lon: result.lon, adrese: result.formatted });
        });
    }
  };

  useEffect(() => {
    //console.log(formData);
  }, [formData]);

  useEffect(() => {
    document.querySelector("#idea").checked = true;
    setFormData({ cat: "idea" });
  }, []);

  return (
    <div className="mapFormCont">
      {/* <div className="mapForm"> */}
      <div className="p-2 my-2 mx-5 bg-raccoon-700/80 rounded-md w-full lg:w-3/5 xl:w-1/2 2xl:w-2/5">
        <div className="mapFormTitle">Pievieno jaunu ierakstu</div>
        <form className="addItemForm">
          <div className="addItemFormLabel">Nosaukums*</div>
          <input className="w-11/12" name="nosaukums" value={formData.nosaukums || ""} onChange={handleChange} />
          <div className="addItemFormLabel">Komentārs*</div>
          <input className="w-11/12" name="komentars" value={formData.komentars || ""} onChange={handleChange} />
          <div className="addItemFormLabel">Adrese</div>
          <input className="w-11/12" name="adrese" value={formData.adrese || ""} onChange={handleChange} />
          <button className="py-1 px-8 bg-indigo-200 hover:bg-indigo-300 shadow-sm shadow-indigo-500 rounded-xl m-3 text-xs border-gray-300" onClick={geoCode}>
            Meklēt adreses koordinātas
          </button>
          <div className="addItemFormLabel">Lat, lon**</div>
          <div className="coordinateDiv">
            <input className="w-full" name="lat" value={formData.lat || ""} onChange={handleChange} />
            <input className="w-full" name="lon" value={formData.lon || ""} onChange={handleChange} />
          </div>
          <div className="addItemFormLabel">Kategorija*</div>
          <div className="flex gap-3 my-2 text-gray-100 items-center justify-center">
            <input name="cat" type="radio" value="idea" id="idea" onChange={handleChange} /> <label htmlFor="idea">Ideja/aktivitāte</label>
            <input name="cat" type="radio" value="vk" id="vk" onChange={handleChange} /> <label htmlFor="vk">Ņam-ņam</label>
            <input name="cat" type="radio" value="kk" id="kk" onChange={handleChange} /> <label htmlFor="kk">Kūkas, kūciņas</label>
          </div>

          {/* <button className="submitButton">Pievienot</button> */}
          <button className="py-1 px-8 bg-indigo-200 hover:bg-indigo-300 shadow-sm shadow-indigo-500 rounded-xl m-3 text-sm border-gray-300" onClick={handleSubmit}>
            Pievienot
          </button>
          <div className="text-xs text-gray-100 text-left w-full p-2">
            * - nepieciešamie lauki
            <br />
            ** - ieraksts parādīsies kartē tikai tad, ja būs koordinātas
          </div>
        </form>
      </div>
    </div>
  );
};

export default InputFormMap;
