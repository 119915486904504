import { useEffect, useState } from "react";
import B40item from "./B40item";

const B40 = ({ user }) => {
  const addZero = (number) => {
    if (!number.toString().split(".")[1]) {
      return `${number}.00`;
    } else if (+number.toString().split(".")[1].length < 2) {
      return `${number}0`;
    } else {
      return number.toString();
    }
  };
  const fetchData = () => {
    fetch(`https://${process.env.REACT_APP_BACKEND_URL}/data?token=${user.accessToken}`)
      .then((response) => response.json())
      .then((d) => setData(d));
  };

  const addItem = () => {
    if (divHidden) {
      setDivHidden(false);
    } else {
      let datums = document.querySelector("#date").value;
      let nosaukums = document.querySelector("#name").value;
      let summa = document.querySelector("#summa").value;
      let sanemejs = document.querySelector("#sanemejs").value;
      let samaksaja = document.querySelector("#samaksaja").value;
      let piezimes = document.querySelector("#piezimes").value;

      fetch(`https://${process.env.REACT_APP_BACKEND_URL}/adddata?token=${user.accessToken}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ datums, nosaukums, summa, sanemejs, samaksaja, piezimes }),
      })
        .then((response) => response.json())
        .then((d) => {
          if (d.message === "ok") {
            document.querySelector("#errorMessage").innerHTML = "";
            setDivHidden(true);
            fetchData();
          } else if (d.error) {
            document.querySelector("#errorMessage").innerHTML = d.error;
          }
        });
    }
  };

  const [data, setData] = useState([]);
  const [divHidden, setDivHidden] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!divHidden) {
      document.querySelector("#addForm").style.display = "grid";
      document.querySelector("#addButton").innerHTML = "Iesniegt ierakstu";
    } else {
      document.querySelector("#addForm").style.display = "none";
      document.querySelector("#addButton").innerHTML = "Pievienot jaunu ierakstu";
    }
  }, [divHidden]);

  return (
    <div className="message">
      <div className="table">
        <div className="tableHeader">
          <div className="tableCell">Datums</div>
          <div className="tableCell">Nosaukums</div>
          <div className="tableCell">Summa</div>
          <div className="tableCell">Saņēmējs</div>
          <div className="tableCell">Samaksāja</div>
          <div className="tableCell">Piezīmes</div>
        </div>
        {Array.isArray(data) && data.map((d) => <B40item data={d} key={d.id} />)}
        <div className="tableRow" id="addForm">
          <div className="tableCell">
            <input id="date" type="date" />
          </div>
          <div className="tableCell">
            <input id="name" />
          </div>
          <div className="tableCell">
            <input id="summa" />
          </div>
          <div className="tableCell">
            <input id="sanemejs" />
          </div>
          <div className="tableCell">
            <input id="samaksaja" />
          </div>
          <div className="tableCell">
            <input id="piezimes" />
          </div>
        </div>
      </div>
      <div id="errorMessage"></div>
      <div className="buttonHolder">
        <button id="addButton" onClick={addItem}>
          Pievienot jaunu ierakstu
        </button>
      </div>
      <div className="infoHolder">Kopsumma {addZero(Math.round(data.reduce((sum, d) => sum + parseFloat(d.summa), 0) * 100) / 100)} EUR</div>
    </div>
  );
};

export default B40;
