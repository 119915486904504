import { getIdToken } from "firebase/auth";
import { useEffect, useState } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import parse from "html-react-parser";

const Map = ({ user }) => {
  const fetchData = async () => {
    const accessToken = await getIdToken(user);
    fetch(`https://${process.env.REACT_APP_BACKEND_URL}/mapdata?token=${accessToken}`)
      .then((response) => response.json())
      .then((d) => setMapData(d));
  };

  const [mapData, setMapData] = useState([]);
  //const [filterValues, setFilterValues] = useState({ vk: true, kk: true, idea: true });

  useEffect(() => {
    fetchData();
  }, []);

  const cakeIcon = L.divIcon({ html: "🧁", className: "mapIcon", iconSize: [35, 35] });
  const placeIcon = L.divIcon({ html: "🍽️", className: "mapIcon", iconSize: [35, 35] });
  const ideaIcon = L.divIcon({ html: "🪧", className: "mapIcon", iconSize: [35, 35] });
  const emptyIcon = L.divIcon({ html: "😥", className: "emptyMapIcon", iconSize: [35, 35] });

  return (
    <MapContainer className="mapCont" center={[56.9, 24.14]} zoom={8}>
      <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      {Array.isArray(mapData) &&
        mapData
          .filter((d) => d.lat && d.lon)
          .map((d) => {
            let sourceText;
            let markerIcon;
            if (d.tabula === "vk") {
              sourceText = "vietu kartē";
              markerIcon = placeIcon;
            } else if (d.tabula === "kk") {
              sourceText = "kūku kartē";
              markerIcon = cakeIcon;
            } else if (d.tabula === "idea") {
              sourceText = "ideju kartē";
              markerIcon = ideaIcon;
            }
            if (d.notExist === 1) {
              markerIcon = emptyIcon;
            }
            let timeAdded;
            if (d.laiks > 0) {
              timeAdded = new Date(parseInt(d.laiks) * 1000).toLocaleDateString("lv-LV");
            }
            return (
              <Marker position={[d.lat, d.lon]} icon={markerIcon} key={d.id}>
                <Popup>
                  <div>
                    <b>{d.nosaukums}</b>
                  </div>
                  <div className="addressPopup">{d.adrese}</div>
                  <div className="infoPopup">{parse(d.komentars || "")}</div>
                  <div className="addressPopup">
                    <i>
                      Lokācija pievienota {sourceText} {timeAdded || ""}
                    </i>
                  </div>
                </Popup>
              </Marker>
            );
          })}
      {/* <div id="overlayDiv" className="absolute top-0 right-0 z-[999] m-2 p-2 bg-raccoon-600/60 rounded-lg flex gap-3 text-gray-200">
        <label>
          <input className="mx-1" type="checkbox" checked={filterValues.kk} onChange={(e) => setFilterValues({ ...filterValues, kk: !filterValues.kk })} /> Kūkas
        </label>
        <label>
          <input className="mx-1" type="checkbox" checked={filterValues.vk} onChange={(e) => setFilterValues({ ...filterValues, vk: !filterValues.vk })} /> Ņam-ņam
        </label>
        <label>
          <input className="mx-1" type="checkbox" checked={filterValues.idea} onChange={(e) => setFilterValues({ ...filterValues, idea: !filterValues.idea })} /> Idejas
        </label>
      </div> */}
    </MapContainer>
  );
};

export default Map;
